import { Component, Inject, ViewEncapsulation, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { CustomerInterface } from 'app/models/customer.model';
import { FolderInterface } from 'app/models/folder.model';
import { Resource } from 'app/models/resource.model';
import { AutocompleteList } from 'app/modules/common/autocompletes/autocomplete/autocomplete.component';
import { FormUtils } from 'app/modules/global/classes/form-utils';
import { FOLDER_NAME_MAX_LENGTH } from 'app/modules/global/constants';
import { REGEXP_FOLDER_NAME } from 'app/modules/global/regexps';
import { CustomerRepositoryService } from 'app/repositories/customer-repository.service';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subscription } from 'rxjs';
import { FolderResourcesRemoveSystemsDialogDataInterface } from '../folder-resources-selector-dialog/folder-resources-selector-dialog.component';
import { ResourcesRepositoryService } from 'app/repositories/resources-repository.service';
import { map } from 'rxjs/operators';
import { UtilsService } from 'app/modules/common/utils/utils.service';

@Component({
  selector: 'app-folder-remove-systems-dialog',
  templateUrl: './folder-remove-systems-dialog.component.html',
  styleUrls: ['./folder-remove-systems-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FolderRemoveSystemsDialogComponent implements OnInit {

  form: FormGroup = new FormGroup({
    editorials: new FormControl(true),
    playlists: new FormControl(true),
    distributions: new FormControl(true),
  });

  preview: any;

  previewSubscription: Subscription;
  loading: boolean;
  count: number = 0;

  totalCount: number = 0;

  deleting: boolean = false;
  deleteIndex: number = 1;

  constructor(
    public matDialogRef: MatDialogRef<FolderRemoveSystemsDialogComponent>,
    private _toastrService: ToastrService,
    private _cdr: ChangeDetectorRef,
    private _i18nService: I18nService,
    private _customerRepositoryService: CustomerRepositoryService,
    private _currentUserService: CurrentUserService,
    private _resourcesRepositoryService: ResourcesRepositoryService,
    @Inject(MAT_DIALOG_DATA) public data: FolderResourcesRemoveSystemsDialogDataInterface,
  ) {

  }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  ngOnInit(): void {
    // if (this.resources.length === 1) {

    if (this.isSingleResource) {
      this.refresh();

      this.form.valueChanges.subscribe(data => {
        this.refresh();
      });
    }
  }

  calcCount(source) {

    console.log('source', source);

    return source ? (
      (source.editorials?.resources ?? 0) +
      (source.editorials?.systems ?? 0) +

      (source.playlists?.resources ?? 0) +
      (source.playlists?.systems ?? 0) +

      (source.distributions?.resources ?? 0) +
      (source.distributions?.distributions ?? 0)
    ) : 0;
  }

  refresh() {

    if (this.previewSubscription) {
      this.previewSubscription.unsubscribe();
    }

    if (this.resources.length > 0) {
      this.loading = true;
      this.previewSubscription = this._resourcesRepositoryService.emptyResource(this.resources[0].id, this.form.value, false).pipe(
        map((data) => {
          if (Object.keys(data).length === 0) {
            throw new Error('No data');
          }
          return data;
        })
      ).subscribe(data => {
        this.preview = data;
        // console.log('data', data);
        this.loading = false;
        this.count = this.calcCount(this.preview);
      }, (error) => {
        // console.log('error', error);
        this.loading = false;
        this.preview = null;
      });
    }
  }

  get resources(): Resource[] {
    return this.data.resources || [];
  }

  get isSingleResource() {
    return this.resources.length === 1;
  }

  emptyResource(resourceId: string) {
    return new Promise((resolve, reject) => {
      this._resourcesRepositoryService.emptyResource(resourceId, this.form.value, true).pipe(
        map((data) => {
          if (Object.keys(data).length === 0) {
            throw new Error('No data');
          }
          return data;
        })
      ).subscribe(data => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    });
  }

  async save() {
    this.deleting = true;
    for (let i = 0; i < this.resources.length; i++) {
      this.deleteIndex = i + 1;
      const data = await this.emptyResource(this.resources[i].id);
      console.log('save data', data);
      if (data) {
        this.totalCount += this.calcCount(data);
        console.log('totalCount', this.totalCount);
      }
    }
    this.deleteIndex++;
    await UtilsService.sleep(2500);
    this.matDialogRef.close(true);
  }
}
