import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Resource, RESOURCE_TYPE } from 'app/models/resource.model';
import { RESOURCE_DURATION_DEFAULT } from 'app/modules/global/constants';
import { DatasetInterface } from 'app/models/dataset.model';

@Component({
  selector: 'app-resource-content',
  templateUrl: './resource-content.component.html',
  styleUrls: ['./resource-content.component.scss'],
})
export class ResourceContentComponent implements OnChanges {
  @Input() content: Resource | DatasetInterface;
  @Input() nested: any;
  @Input() duration: number;
  @Input() index: number;
  @Input() name: boolean;
  @Input() thumb: boolean;
  @Input() icon: boolean;
  @Input() details: boolean;
  @Input() modalPreview: boolean;
  @Input() wide: boolean;
  @Input() compact: boolean;

  @Input() showInlineTimerForVideos: boolean = false;
  @Input() showBitrateForVideos: boolean = false;

  @Input() editDuration: boolean = false;
  @Output() editDurationClick: EventEmitter<any> = new EventEmitter();

  isResource: boolean;
  isDContent: boolean;
  isNested: boolean;
  isImage: boolean;
  isVideo: boolean;

  ngOnChanges() {
    this.isResource = false;
    this.isDContent = false;
    this.isNested = false;
    this.isImage = false;
    this.isVideo = false;

    const resource = <Resource>this.content;
    const dcontent = <DatasetInterface>this.content;

    switch (true) {
      case !!this.nested: {
        this.isNested = true;
        break;
      }
      case !!dcontent.template || dcontent.smartobjects: {
        this.isDContent = true;
        break;
      }
      case !!resource.fileinfo: {
        this.isResource = true;
        const resource = this.content as Resource;
        this.isImage = resource.type === RESOURCE_TYPE.images;
        this.isVideo = resource.type === RESOURCE_TYPE.videos;
        break;
      }
    }
  }
}
