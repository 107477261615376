import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() { }

  getRandomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  static random(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  getRandomRGBColor() {
    const color = this.getRandomColor();
    return this.hexToRgb(color);
  }

  static sleep(ms: number) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });
  }

  static hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)})` : null;
  }

  static componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  static rgbToHex(r, g, b) {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  hexToRgb(hex) {
    return UtilsService.hexToRgb(hex);
  }

  componentToHex(c) {
    return UtilsService.componentToHex(c);
  }

  rgbToHex(r, g, b) {
    return UtilsService.rgbToHex(r, g, b);
  }

  equals(obj1: object, obj2: object, prop?: string) {
    if (prop) {
      // console.log('confronto per prop');
      if (!!obj1 && !!obj2 && obj1[prop] === obj2[prop]) {
        return true;
      }
    } else {
      // console.log('confronto per stringify');
      if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
        return true;
      }
    }
    return false;
  }

  arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  getObjectPath(object: any, path: string) {
    return path.split('.').reduce((o, i) => (o !== undefined && o[i] !== undefined ? o[i] : undefined), object);
  }

  startDownload(fileUrl: string) {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.click();
  }
}
