<div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="flex-start center" [class.wide]="wide">
  <!-- EXPANDED -->
  <ng-container *ngIf="!compact">
    <!-- THUMB -->
    <div *ngIf="thumb" class="thumb" ngClass.xs="thumb-xs" [class.pointer]="isClickable">
      <div matRipple [matRippleDisabled]="!isClickable" [openResource]="isClickable && resource"
        class="resource-preview accent-200-bg" backgroundImage backgroundSize="cover" [backgroundImageSrc]="src">
        <!-- ICON -->
        <app-resource-icon *ngIf="icon" class="resource-preview-icon" [resource]="resource"></app-resource-icon>
      </div>
    </div>
  </ng-container>

  <!-- COMPACT -->
  <ng-container *ngIf="compact">
    <!-- ICON -->
    <app-resource-icon *ngIf="icon" class="resource-title-icon grey-400-fg mr-4"
      [resource]="resource"></app-resource-icon>
  </ng-container>

  <!-- INFO -->
  <div *ngIf="hasMetadata" fxLayout="column" class="app-resource-content-info ellipsis">
    <!-- TITLE -->
    <div fxLayout="row" fxLayoutAlign="flex-start center">
      <div class="resource-content-index mr-4" *ngIf="hasIndex">{{ index }}</div>
      <!-- ICON -->
      <app-resource-icon *ngIf="hasNameIcon" class="resource-title-icon grey-400-fg mr-4"
        [resource]="resource"></app-resource-icon>

      <ng-container *ngIf="name && title">
        <!-- NAME LINK -->
        <a class="ellipsis cursor-pointer" *ngIf="compact && isClickable" href [openResource]="resource">
          <app-name-ext class="title ellipsis" [value]="title"></app-name-ext>
        </a>

        <!-- NAME -->
        <app-name-ext *ngIf="!compact || !isClickable" class="title ellipsis" [value]="title"></app-name-ext>
      </ng-container>

    </div>

    <!-- DETAILS -->
    <div *ngIf="details" class="font-size-12 resource-details" [ngClass]="{ 'pt-4': !compact, 'grey-400-fg': compact }"
      fxLayout="column">
      <!-- RESOURCE -->
      <div fxLayout="row" fxLayoutGap="8px">
        <!-- RESOLUTION -->
        <span *ngIf="width && height" fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis"
          [matTooltip]="'Risoluzione' | translate">
          <mat-icon class="grey-400-fg mr-4 app-icon-xs">aspect_ratio</mat-icon>
          <app-resolution class="details ellipsis" [width]="width" [height]="height"></app-resolution>
        </span>
        <!-- DURATION -->
        <span fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <div *ngIf="editDuration" class="mr-4" [matTooltip]="'Modifica durata' | translate">
            <button class="app-icon-btn-sm" color="accent" mat-icon-button (click)="onEditDurationClick($event)">
              <mat-icon class="app-icon-xs">edit</mat-icon>
            </button>
          </div>
          <span fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis" [matTooltip]="'Durata' | translate">
            <mat-icon *ngIf="!editDuration" class="app-icon-xs grey-400-fg mr-4">timer</mat-icon>
            <span class="details ellipsis" [ngClass]="{ 'orange-400-fg': time !== defaultDuration }">
              <app-duration [time]="time" [options]="{ hideEmpty: true }"></app-duration>
            </span>
          </span>
        </span>
      </div>

    </div>
  </div>
</div>