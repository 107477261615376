<!-- NESTED VIEW -->
<ng-container *ngIf="isNested">
  <app-resource-content-nested [nested]="nested" [index]="index" [name]="name" [thumb]="thumb" [icon]="icon"
    [details]="details" [compact]="compact"></app-resource-content-nested>
</ng-container>

<!-- IMAGE VIEW -->
<ng-container *ngIf="isImage">
  <app-resource-content-image [resource]="content" [duration]="duration" [index]="index" [name]="name" [thumb]="thumb"
    [icon]="icon" [details]="details" [modalPreview]="modalPreview" [wide]="wide" [compact]="compact"
    [editDuration]="editDuration" (editDurationClick)="editDurationClick"></app-resource-content-image>
</ng-container>

<!-- VIDEO VIEW -->
<ng-container *ngIf="isVideo">
  <app-resource-content-video [resource]="content" [duration]="duration" [index]="index" [name]="name" [thumb]="thumb"
    [icon]="icon" [details]="details" [modalPreview]="modalPreview" [wide]="wide" [compact]="compact"
    [editDuration]="editDuration" [showInlineTimerForVideos]="showInlineTimerForVideos"
    [showBitrateForVideos]="showBitrateForVideos" (editDurationClick)="editDurationClick"></app-resource-content-video>
</ng-container>

<!-- DCONTENT VIEW -->
<ng-container *ngIf="isDContent">
  <app-resource-content-dcontent [dcontent]="content" [duration]="duration" [index]="index" [name]="name"
    [thumb]="thumb" [icon]="icon" [details]="details" [modalPreview]="modalPreview" [wide]="wide" [compact]="compact"
    [editDuration]="editDuration" (editDurationClick)="editDurationClick"></app-resource-content-dcontent>
</ng-container>