import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ring-chart',
  templateUrl: './ring-chart.component.html',
  styleUrls: ['./ring-chart.component.scss']
})
export class RingChartComponent implements OnInit {

  @Input() value: number = 0;
  @Input() width: number = 80;
  @Input() borderWidth: number = 10;
  @Input() bgColor: string = '#e2e2e2';
  @Input() fgColor: string = '#55b2be';
  @Input() showText: boolean = false;

  radius: number;
  circumference: number;

  constructor(
    private _ref: ElementRef
  ) { }

  ngOnInit() {
    this.refresh();

    // setInterval(() => {
    //   this.value += 10;
    //   this.refresh();
    // }, 2000);
  }

  ngOnChanges() {
    this.refresh();
  }

  refresh() {
    if (this.value > 100) this.value = 100;
    if (this.value < 0) this.value = 0;
    this.value = this.value ?? 0;

    this.radius = (this.width - this.borderWidth * 2) / 2;
    this.circumference = 2 * Math.PI * this.radius;

    const ring: HTMLElement = this._ref.nativeElement;
    ring.style.width = `${this.width}px`;
    ring.style.height = `${this.width}px`;

    const bg: HTMLElement = this._ref.nativeElement.querySelector('.progress-ring-background');
    bg.style.strokeWidth = `${this.borderWidth}`;
    bg.style.stroke = this.bgColor;

    const circle: HTMLElement = this._ref.nativeElement.querySelector('.progress-ring-circle');
    circle.style.strokeWidth = `${this.borderWidth}`;
    circle.style.stroke = this.value > 0 ? this.fgColor : this.bgColor;

    // SET CIRCUMFERENCE
    circle.style.strokeDasharray = `${this.circumference}`;

    // SET PROGRESS
    const offset = this.circumference - (this.value / 100) * this.circumference;
    circle.style.strokeDashoffset = `${offset}`;

    // SET PROGRESS TEXT
    if (this.showText) {
      const text = this._ref.nativeElement.querySelector('.progress-percentage');
      text.textContent = `${Math.round(this.value)}%`;
    }
  }


}
