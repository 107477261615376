<!-- RESOURCE -->
<div class="ellipsis" fxFlex="100" fxLayout="column" fxFlex *ngIf="resource">
  <header class="ellipsis" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="ellipsis" fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
      <mat-icon *ngIf="resource.type === resourceType.videos">videocam</mat-icon>
      <mat-icon *ngIf="resource.type === resourceType.images">image</mat-icon>
      <span class="ellipsis">{{ resource.name }}</span>
    </h2>
    <button mat-icon-button (click)="bottomSheetRef.dismiss()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="resource-preview-container" fxFlex="100" fxLayout="row" fxLayout.xs="column">
    <div class="resource-preview-container-media" fxFlex="360px" fxFlex.gt-xs="100">
      <resource-mini-preview [resource]="resource"></resource-mini-preview>
    </div>
    <div class="ml-4 resource-preview-metadata font-size-12">
      <app-resource-metadata *ngIf="hasMetadata" [metadata]="resource.metadata"
        [transcodedMetadata]="resource.transcodedMetadata" [head]="resource.head"></app-resource-metadata>
      <mat-list>
        <mat-divider *ngIf="hasMetadata"></mat-divider>
        <mat-list-item *ngIf="!!resource.folder">
          <strong fxFlex="50%">{{ 'Cartella' | translate }}</strong>
          <span class="text-right ellipsis" fxFlex>
            {{ resource.folder.name }}
          </span>
        </mat-list-item>
        <mat-list-item>
          <strong fxFlex="50%">{{ 'Creato' | translate }}</strong>
          <span class="text-right" fxFlex>
            <app-date [date]="resource.created_at" formatter="medium"></app-date>
          </span>
        </mat-list-item>
        <mat-list-item *ngIf="resource.updated_at">
          <strong fxFlex="50%">{{ 'Aggiornato' | translate }}</strong>
          <span class="text-right" fxFlex>
            <app-date [date]="resource.updated_at" formatter="medium"></app-date>
          </span>
        </mat-list-item>
        <mat-list-item *ngIf="resource.updated_by">
          <strong fxFlex="50%">{{ 'Aggiornato da' | translate }}</strong>
          <span class="text-right" fxFlex>
            {{ resource.updated_by.username }}
          </span>
        </mat-list-item>
        <mat-list-item *ngIf="resource.brand">
          <strong fxFlex="50%">{{ 'Brand' | translate }}</strong>
          <span class="text-right" fxFlex>
            {{ resource.brand.name }}
          </span>
        </mat-list-item>
      </mat-list>
      <div fxLayout class="p-16" fxLayoutAlign="center center">
        <a target="_blank" href="{{ resource.publicAvailableSource }}">
          <button mat-raised-button>{{ 'Scarica' | translate }}</button>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- DCONTENT -->
<div class="ellipsis" fxFlex="100" fxLayout="column" fxFlex *ngIf="dcontent">
  <header class="ellipsis" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="ellipsis" fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
      <app-resource-icon [dcontent]="dcontent"></app-resource-icon>
      <span class="ellipsis">{{ dcontent.name }}</span>
    </h2>
    <button mat-icon-button (click)="bottomSheetRef.dismiss()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="resource-preview-container" fxFlex="100" fxLayout="row" fxLayout.xs="column">
    <div class="resource-preview-container-media" fxFlex="360px" fxFlex.gt-xs="100">
      <iframe class="dcontent-iframe-preview" src="https://dev-programmatic.fluidnext.com"
        title="W3Schools Free Online Web Tutorials"></iframe>
    </div>
    <div class="ml-4 resource-preview-metadata">
      <app-dcontent-metadata [dcontent]="dcontent"></app-dcontent-metadata>
      <mat-list>
        <mat-divider></mat-divider>
        <mat-list-item>
          <strong fxFlex="50%">{{ 'Creato' | translate }}</strong>
          <span class="text-right" fxFlex>
            <app-date [date]="dcontent.created_at" formatter="short"></app-date>
          </span>
        </mat-list-item>
        <mat-list-item>
          <strong fxFlex="50%">{{ 'Aggiornato' | translate }}</strong>
          <span class="text-right" fxFlex>
            <app-date [date]="dcontent.updated_at" formatter="short"></app-date>
          </span>
        </mat-list-item>
        <mat-list-item *ngIf="dcontent.updated_by">
          <strong fxFlex="50%">{{ 'Aggiornato da' | translate }}</strong>
          <span class="text-right" fxFlex>
            {{ dcontent.updated_by.username }}
          </span>
        </mat-list-item>
        <mat-list-item *ngIf="dcontent.template.brand">
          <strong fxFlex="50%">{{ 'Brand' | translate }}</strong>
          <span class="text-right" fxFlex>
            {{ dcontent.template.brand.name }}
          </span>
        </mat-list-item>
      </mat-list>
      <div fxLayout class="p-16" fxLayoutAlign="center center">
        <a [routerLink]="'/datasets/' + dcontent.id" (click)="$event.stopPropagation()" target="_blank">
          <button mat-raised-button>{{ 'Val al Set di Dati' | translate }}</button>
        </a>
      </div>
    </div>
  </div>
</div>