import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { RESOURCE_DURATION_DEFAULT } from 'app/modules/global/constants';
import { DatasetInterface } from 'app/models/dataset.model';

@Component({
  selector: 'app-resource-content-dcontent',
  templateUrl: './resource-content-dcontent.component.html',
  styleUrls: ['./resource-content-dcontent.component.scss'],
})
export class ResourceContentDContentComponent implements OnChanges {
  @Input() dcontent: DatasetInterface;
  @Input() duration: number;
  @Input() index: number;
  @Input() name: boolean;
  @Input() thumb: boolean;
  @Input() icon: boolean;
  @Input() details: boolean;
  @Input() modalPreview: boolean;
  @Input() wide: boolean;
  @Input() compact: boolean;

  @Input() editDuration: boolean = false;
  @Output() editDurationClick: EventEmitter<any> = new EventEmitter();

  title: string;
  width: number;
  height: number;
  src: string;
  time: any;

  hasIndex: boolean;
  hasMetadata: boolean;
  hasNameIcon: boolean;

  isClickable: boolean;
  isLink: boolean;

  defaultDuration: number;

  resourceDurationDefault = RESOURCE_DURATION_DEFAULT;

  constructor() { }

  onEditDurationClick(event) {
    if (this.editDuration) {
      this.editDurationClick.emit({ event: event, value: this.dcontent });
      event.stopPropagation();
    }
  }

  ngOnChanges() {
    if (!this.dcontent) {
      return;
    }

    this.defaultDuration = +this.dcontent.duration || RESOURCE_DURATION_DEFAULT;

    this.hasIndex = false;
    if (this.index !== undefined) {
      this.hasIndex = true;
    }

    this.hasMetadata = this.name || this.details || this.hasNameIcon;
    this.hasNameIcon = this.icon && !this.thumb;

    // TODO: when DCONTENT PREVIEW ready, remove "false"
    this.isClickable = false && this.modalPreview;
    this.isLink = !this.thumb && this.isClickable;

    const someInfo = this.thumb || this.hasMetadata;

    if (!someInfo || this.details || this.hasNameIcon) {
      this.name = true;
    }

    if (this.name) {
      this.title = this.dcontent.name;
    }

    if (this.thumb) {
      this.src = this.dcontent.thumb;
    }

    this.time = +this.duration;

    const template = this.dcontent.template;
    if (template && !Array.isArray(template)) {
      this.width = template.width;
      this.height = template.height;
      if (!this.time) {
        this.time = +this.dcontent.duration || +this.resourceDurationDefault;
      }
    }
  }
}
