<div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="flex-start center">

  <ng-container *ngIf="!compact">
    <!-- THUMB -->
    <div *ngIf="thumb" class="thumb thumb-nested" ngClass.xs="thumb-xs">
      <div class="resource-preview">
        <app-resource-nested-thumb [nested]="nested"></app-resource-nested-thumb>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="compact">
    <!-- ICON -->
    <app-resource-icon *ngIf="icon" class="resource-title-icon grey-400-fg mr-4" [resource]="resource"
      [dcontent]="dcontent" [nested]="nested"></app-resource-icon>
  </ng-container>

  <!-- INFO -->
  <div *ngIf="hasMetadata" fxLayout="column" class="app-resource-content-info ellipsis">
    <!-- TITLE -->
    <div fxLayout="row" fxLayoutAlign="flex-start center">
      <div class="resource-content-index mr-4" *ngIf="hasIndex">{{ index }}</div>
      <!-- ICON -->
      <app-resource-icon *ngIf="hasNameIcon" class="resource-title-icon grey-400-fg mr-4" [resource]="resource"
        [dcontent]="dcontent" [nested]="nested"></app-resource-icon>
      <!-- NAME -->
      <app-name-ext *ngIf="name && title" class="title ellipsis" [value]="title"></app-name-ext>
    </div>
    <!-- DETAILS -->
    <div *ngIf="details" class="font-size-12 resource-details" [ngClass]="{ 'pt-4': !compact, 'grey-400-fg': compact }"
      fxLayout="column">
      <div fxLayout="row" fxLayoutGap="8px">
        <!-- RESOURCES -->
        <span fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <mat-icon class="app-icon-xs grey-400-fg mr-4">filter</mat-icon>
          <span class="details ellipsis">
            {{ '{count} elementi' | translate: { count: nested.resources.length } }}</span>
        </span>
        <!-- PAGES -->
        <span fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <mat-icon class="app-icon-xs grey-400-fg mr-4">remove_red_eye</mat-icon>
          <span class="details ellipsis">{{ '{count} per pagina' | translate: { count: nested.page } }}</span>
        </span>
      </div>
    </div>
  </div>
</div>