<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ 'Rimuovi risorse da impianti' | translate }}</span>
      <button *ngIf="!deleting" mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-24 m-0" mat-dialog-content>

    <div fxLayout="column">

      <!-- RESOURCES PREVIEW -->
      <div class="mb-24">
        <mat-accordion fxFlex="100">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <!-- TITLE -->
              <mat-panel-title> <strong>{{ 'Risorse selezionate' | translate }} ({{ resources.length }})</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
              <div [style.width]="isSingleResource ? '100%' : '140px'" *ngFor="let resource of resources; let i = index"
                class="mb-4">
                <app-resource-content [content]="resource" [thumb]="true" [name]="isSingleResource"
                  [details]="isSingleResource" [modalPreview]="true"></app-resource-content>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <!-- TITLE -->
      <div class="mb-12 h3">
        <strong>
          {{ 'Selezionare le sezioni da cui rimuovere le risorse' | translate }}
        </strong>
      </div>

      <!-- CHECKBOXES -->
      <div class="mb-12" [ngClass]="{ 'fluid-disabled': deleting }">
        <form [formGroup]="form" name="editFolder" novalidate>
          <div fxLayout="column" fxLayout.gt-sm="row" fxFlex="100" class="text-center">

            <!-- EDITORIALS -->
            <div fxFlex="100" fxFlex.gt-sm="calc(33% - 12px)" class="p-8 pt-16 pb-0 border border-radius-4 mb-4 mr-4"
              [ngClass]="{ 'accent-100-bg': !loading && form.get('editorials').value, 'grey-100-bg': !form.get('editorials').value }">
              <div class="mb-16">
                <mat-checkbox name="folder-remove-systems-editorials" formControlName="editorials">{{
                      'Editoriali' | translate
                    }}</mat-checkbox>
              </div>
              <div *ngIf="isSingleResource" class="py-8 mb-8" [ngClass]="{ 'loading': loading }">

                <div class="mb-12">
                  <div class="grey-400-fg">{{'risorse' | translate}}</div>
                  <div class="h3">
                    <strong>{{ preview?.editorials?.resources ?? '--' }}</strong>
                  </div>
                </div>
                <div class="">
                  <div class="grey-400-fg">{{'impianti' | translate}}</div>
                  <div class="h3">
                    <strong>{{ preview?.editorials?.systems ?? '--' }}</strong>
                  </div>
                </div>
              </div>
            </div>

            <!-- PLAYLISTS -->
            <div fxFlex="100" fxFlex.gt-sm="calc(33% - 12px)" class="p-8 pt-16 pb-0 border border-radius-4 mb-4 mr-4"
              [ngClass]="{ 'accent-100-bg': !loading && form.get('playlists').value, 'grey-100-bg': !form.get('playlists').value }">
              <div class="mb-16">
                <mat-checkbox name="folder-remove-systems-playlists" formControlName="playlists">{{
                      'Playlist' | translate
                    }}</mat-checkbox>
              </div>
              <div *ngIf="isSingleResource" class="py-8 mb-8" [ngClass]="{ 'loading': loading }">
                <div class="mb-12">
                  <div class="grey-400-fg">{{'risorse' | translate}}</div>
                  <div class="h3">
                    <strong>{{ preview?.playlists?.resources ?? '--' }}</strong>
                  </div>
                </div>
                <div class="">
                  <div class="grey-400-fg">{{'impianti' | translate}}</div>
                  <div class="h3">
                    <strong>{{ preview?.playlists?.systems ?? '--' }}</strong>
                  </div>
                </div>
              </div>
            </div>

            <!-- DISTRIBUTIONS -->
            <div fxFlex="100" fxFlex.gt-sm="calc(33% - 12px)" class="p-8 pt-16 pb-0 border border-radius-4 mb-4 mr-4"
              [ngClass]="{ 'accent-100-bg': !loading && form.get('distributions').value, 'grey-100-bg': !form.get('distributions').value }">
              <div class="mb-16">
                <mat-checkbox name="folder-remove-systems-distributions" formControlName="distributions">{{
                      'Distribuzioni' | translate
                    }}</mat-checkbox>
              </div>
              <div *ngIf="isSingleResource" class="py-8 mb-8" [ngClass]="{ 'loading': loading }">

                <div class="mb-12">
                  <div class="grey-400-fg">{{'risorse' | translate}}</div>
                  <div class="h3">
                    <strong>{{ preview?.distributions?.resources ?? '--' }}</strong>
                  </div>
                </div>
                <div class="">
                  <div class="grey-400-fg">{{'distribuzioni' | translate}}</div>
                  <div class="h3">
                    <strong>{{ preview?.distributions?.distributions ?? '--' }}</strong>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>

      <!-- PREVIEW -->
      <ng-container *ngIf="!deleting">
        <!-- INFO -->
        <ng-container *ngIf="!loading && isSingleResource">
          <div class="mb-12 h3">
            <span>
              {{ 'Collegamenti individuati' | translate }}: <strong>{{ count }}</strong>
            </span>
          </div>

          <div *ngIf="count === 0" class="p-8 mb-12 border-radius-4 yellow-200-bg">
            {{ 'Non ci sono collegamenti per le risorse selezionate' | translate }}
          </div>
        </ng-container>

        <!-- ACTION TEXT -->
        <div *ngIf="!isSingleResource || count > 0" class="mt-12 mb-12 h3">
          <span>
            {{ 'Le risorse selezionate verranno rimosse dalle programmazioni delle sezioni selezionate' | translate }}
          </span>
        </div>
      </ng-container>

      <!-- DELETING -->
      <ng-container *ngIf="deleting">
        <div class="p-24 text-center">
          <div class="h2 mb-8" fxLayout="row" fxLayoutAlign="center center">
            <ng-container *ngIf="deleteIndex <= resources.length">
              <mat-icon class="mr-8 orange-400-fg app-rotate">sync</mat-icon>
              {{ 'Rimozione in corso' | translate }}&hellip;
              <span>{{ deleteIndex }}/{{
                resources.length }}</span>
            </ng-container>
            <ng-container *ngIf="deleteIndex > resources.length">
              <mat-icon class="mr-8 green-400-fg">check</mat-icon> {{ 'Rimozione completata' }}
            </ng-container>
          </div>
          <div class="mb-24" fxLayout="row" fxLayoutAlign="center center">
            <span class="h3 mr-8 grey-500-fg">
              Collegamenti rimossi:
            </span>
            <span class="h1">{{ totalCount }}</span>
          </div>

          <div class="p-8 border-radius-4 orange-200-bg">
            {{ 'Attendere la fine del processo, non chiudere la pagina' | translate }}
          </div>

        </div>
      </ng-container>

    </div>


  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
    <button [disabled]="deleting" mat-button (click)="matDialogRef.close()">{{ 'Annulla' | translate }}</button>
    <button color="primary" type="button" mat-raised-button
      [disabled]="loading || deleting || (isSingleResource && count === 0)"
      (click)="save()">{{ 'Conferma rimozione' | translate }}</button>
  </div>
</div>