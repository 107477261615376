import { Injectable } from '@angular/core';
import { DataTransformAbstractUp } from 'app/modules/global/classes/data.transform.abstract.up';
import { CircuitInterface } from 'app/models/circuit.model';

@Injectable({
  providedIn: 'root',
})
export class CircuitDataTransformUp extends DataTransformAbstractUp {

  transform(circuit: CircuitInterface): CircuitInterface {
    return circuit;
  }

}
