import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { SystemInterface } from "./system.model";

export interface CustomerInterface {
    id?: string;
    name?: string;
    note?: string;
    systems?: SystemInterface[];
    staticKey?: boolean;
    bitRateLimit?: number;
}

export class Customer implements CustomerInterface {
    id: string;
    name: string;
    note: string;
    systems: SystemInterface[] = [];
    staticKey: boolean = false;
    bitRateLimit: number = 10_000;

    /**
     * Constructor
     *
     * @param _customer
     */
    constructor(private _customer?: CustomerInterface) {
        Object.assign(this, this._customer);
        delete this._customer;
    }
}

export enum CustomerLicenseTypeEnum {
    'assigned' = 'assigned',
    'available' = 'available',
    'archived' = 'archived',
}

export const CustomerLicenseTypeList = [{
    value: CustomerLicenseTypeEnum.assigned,
    label: _('Assegnate@@Licenze assegnate'),
}, {
    value: CustomerLicenseTypeEnum.available,
    label: _('Disponibili@@Licenze disponibili'),
}, {
    value: CustomerLicenseTypeEnum.archived,
    label: _('Archiviate@@Licenze archiviate'),
}];
