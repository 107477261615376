import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-resource-content-nested',
  templateUrl: './resource-content-nested.component.html',
  styleUrls: ['./resource-content-nested.component.scss'],
})
export class ResourceContentNestedComponent implements OnChanges {
  @Input() nested: any;
  @Input() index: number;
  @Input() name: boolean;
  @Input() thumb: boolean;
  @Input() icon: boolean;
  @Input() details: boolean;
  @Input() compact: boolean;

  title: string;
  hasIndex: boolean;
  hasMetadata: boolean;
  hasNameIcon: boolean;

  constructor() { }

  ngOnChanges() {
    if (!this.nested) {
      return;
    }

    this.hasIndex = false;
    if (this.index !== undefined) {
      this.hasIndex = true;
    }

    this.hasMetadata = this.name || this.details || this.hasNameIcon;
    this.hasNameIcon = this.icon && !this.thumb;

    const someInfo = this.thumb || this.hasMetadata;

    // FORCE NAME IF EMPTY PREVIEW
    if (!someInfo || this.details || this.hasNameIcon) {
      this.name = true;
    }

    if (this.name) {
      this.title = this.nested.name;
    }
  }
}
