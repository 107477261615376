import { Injectable } from '@angular/core';
import { DataTransformAbstractDown } from 'app/modules/global/classes/data.transform.abstract.down';
import { DatePipe } from '@angular/common';
import { CircuitInterface } from 'app/models/circuit.model';

@Injectable({
  providedIn: 'root',
})
export class CircuitDataTransformDown extends DataTransformAbstractDown {

  constructor(protected datePipe: DatePipe) {
    super();
  }

  transform(circuit: CircuitInterface): CircuitInterface {
    if (Array.isArray(circuit.circuits)) {
      circuit.circuits = <any>circuit.circuits.map(v => v.id);
    }
    if (Array.isArray(circuit.systems)) {
      circuit.systems = <any>circuit.systems.map(v => v.id);
    }
    return circuit;
  }
}
