import { Component, Input, OnChanges } from '@angular/core';

/**
 *
 *
 * @export
 * @class ResourceNestedThumbComponent
 * @description Show resource nested thumb preview
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-resource-nested-thumb',
  templateUrl: './resource-nested-thumb.component.html',
  styleUrls: ['./resource-nested-thumb.component.scss'],
})
export class ResourceNestedThumbComponent implements OnChanges {
  @Input() nested: any;

  covers: any[] = [];
  fake: any[] = [];

  constructor() { }

  ngOnChanges() {
    if (!this.nested) {
      return;
    }
    this.covers = this.nested.resources.slice(0, 3);
    if (this.covers.length < 3) {
      this.fake = new Array(3 - this.covers.length);
    }
  }
}
